/* ContactForm.css */

.contact-form {
    margin: 20px;
  }
  
  .contact-form h2 {
    margin-bottom: 10px;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column; /* Stack the form elements */
  }
  
  .contact-form label {
    margin-bottom: 5px; /* Space between label and input */
  }
  
  .contact-form input,
  .contact-form textarea {
    margin-bottom: 15px; /* Space below inputs */
    padding: 10px; /* Padding inside inputs */
    border: 1px solid #ccc; /* Border style */
    border-radius: 5px; /* Rounded corners */
  }
  
  .contact-form button {
    background-color: #007acc; /* Button color */
    color: white; /* Text color */
    padding: 10px; /* Padding around button */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
  }
  
  .contact-form button:hover {
    background-color: #005999; /* Darker shade on hover */
  }
  