/* App.css */

/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Oxanium', sans-serif; /* For content */
  background-color: #FFFFFF; /* Primary Background */
  color: #1E293B; /* Primary Text */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Philosopher', cursive; /* For headings */
  color: #1E293B; /* Heading Text */
}

/* Header Styling */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #F3F4F6; /* Secondary Background */
}

/* Logo Styling */
.logo a {
  font-family: 'Merienda', sans-serif;
  font-size: 5rem;
  color: #1E293B; /* Primary Text for logo */
  text-decoration: none;
  margin-left: 2rem;
}

.harsh {
  font-family: 'Amita', cursive;
  margin-right: .2rem;
  color: #4B5563; /* Secondary Text for 'Harsh' */
}

.very {
  font-family: 'Merienda', cursive;
  margin-right: 0.2rem;
  color: #4B5563; /* Secondary Text for 'very' */
}

.dev {
  font-family: 'Chokokutai', cursive;
  color: #4B5563; /* Accent Color for '.dev' */
}

/* Header Tabs */
.header-tabs a {
  margin: 0 1rem;
  color: #4B5563; /* Primary Text color for tabs */
  text-decoration: none;
  font-family: "Playwrite FR Trad", cursive;
}

.header-tabs a:hover {
  color: #b4aa9c; /* Accent Color on hover */
}

/* App Header */
.app-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #F3F4F6; /* Secondary Background */
  color: #1E293B; /* Primary Text */
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  height: 70px; /* Ensure a consistent header height */
}

.app-header h1 {
  margin: 0;
}

/* Portfolio Styles */
.coding-portfolio,
.photography-portfolio {
  padding: 20px;
}

/* Project Styles */
.project {
  background-color: #FFFFFF; /* White background for project cards */
  border: 1px solid #E5E7EB; /* Light border */
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px;
}

.project h3 {
  color: #1E293B; /* Primary Text color for project headers */
}

.project a {
  color: #2563EB; /* Accent Color for project links */
  text-decoration: none;
}

.project a:hover {
  color: #FB923C; /* Highlight Color on hover */
  text-decoration: underline;
}

/* Photo Styles */
.photo {
  margin-bottom: 20px;
}

.photo img {
  width: 100%; /* Responsive images */
  height: auto;
  border-radius: 5px;
  border: 1px solid #E5E7EB; /* Light border for photos */
}

/* Work Experience and Resume Styles */
.work-experience, .resume {
  margin-top: 30px;
}

/* About Section Styles */
.about {
  margin-top: 30px;
}

section {
  height: 100vh; /* Full screen height */
  padding-top: 70px; /* Offset for fixed header */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F9FAFB; /* Primary Background for sections */
}

.about, .projects, .skills, .blog, .certifications, .contact, .resume {
  text-align: center;
  font-family: 'Oxanium', sans-serif;
  color: #4B5563; /* Content Text */
}

/* Button Styles */
button {
  background-color: #2563EB; /* Button Background */
  color: #FFFFFF; /* Button Text */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Oxanium', sans-serif;
}

button:hover {
  background-color: #FB923C; /* Button Hover Color */
}
