/* SwitchButton.css */

.switch-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 120px; /* Size of the button container */
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
}

.switch-button img {
  width: 100%; /* Make icon fit the button size */
  height: auto;
  border-radius: 50%; /* Keep the image circular */
  transition: transform 0.3s; /* Smooth animation on hover */
}

.switch-button:hover img {
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}
