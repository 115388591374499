/* Apply Oxanium font to all main content */
body {
    font-family: 'Oxanium', sans-serif;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth; /* Smooth scroll for in-page navigation */
    color: #1E293B; /* Primary Text */
    background-color: #FFFFFF; /* Primary Background */
}

/* Heading font style: Playball for section headers */
h1, h2, h3, h4, h5, h6 {
    font-family: 'Playball', sans-serif;
    color: #1E293B; /* Dark color for headings */
    margin: 0.5em 0;
}

/* Full-screen section styling */
.section {
    height: calc(100vh-70px);
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #F9FAFB; /* Primary Background */
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 0.6s ease, transform 0.6s ease;
}

/* Highlight background on section hover */
.section:hover {
    background-color: #E5E7EB; /* Secondary Background */
}
.section.in-view {
    opacity: 1;
    transform: scale(1);
}
/* Navigation buttons styling */
.navigation {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }
  
  .prev-button, .next-button {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .prev-button:hover, .next-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
/* Projects Section Styling */
.projects-section {
    padding-bottom: 1rem;
    width: 100%;
    overflow-x: auto;
    display: flex;
    justify-content: flex-start;
    gap: 1.5rem;
    scroll-snap-type: x mandatory;
    position: relative; /* Required for positioning the navigation buttons */
  }
  
  .projects-container {
    display: flex;
    gap: 1.5rem;
    padding-top: 100px;
    transition: transform 0.5s ease; /* Smooth transition for scroll effect */
  }
  
  .project {
    flex: 0 0 auto;
    width: 250px;
    height: 350px;
    border: 1px solid #E5E7EB;
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    overflow: hidden;
    box-sizing: border-box;
    transition: transform 0.3s ease;
    scroll-snap-align: center; /* Ensures snapping to the center of the viewport */
  }
  
  .project h3 {
    margin: 0;
  }
  
  .project p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }
  
  .project a {
    color: #2563EB; /* Accent Color */
    text-decoration: none;
  }
  
  .project a:hover {
    text-decoration: underline;
    color: #FB923C; /* Highlight Color */
  }
  
  /* Focused project centered */
  .project.centered {
    transform: scale(1.1); /* Slight zoom-in effect for centered project */
  }
  
  .projects-section::-webkit-scrollbar {
    display: none;
  }
  
  .projects-section {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

/* Link styling for other content */
a {
    color: #2563EB; /* Accent Color */
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    color: #FB923C; /* Highlight Color on hover */
}

/* Portfolio container: smooth scroll behavior */
.portfolio-container {
    scroll-behavior: smooth;
}

/* Specific styling for other sections and elements can go here */
