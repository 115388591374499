/* AssistantModal.css */
body {
  font-family: 'Oxanium', sans-serif; /* For content */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Philosopher', sans-serif; /* For headings */
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Dark semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure the modal is above other content */
}

.modal-content {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.assistant-images {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px; /* Space below the images */
}

.assistant-image {
  width: 200px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  border-radius: 50%; /* Optional: Make images circular */
}

.assistant-options {
  margin-top: 20px;
}

.assistant-options button {
  margin: 70px;
  margin-bottom: 0%;
  margin-top: 0%;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007acc;
  color: white;
  cursor: pointer;
}

.assistant-options button:hover {
  background-color: #005999; /* Darker shade on hover */
}
